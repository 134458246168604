import { useDispatch, useSelector } from 'react-redux'
import {
  getDistributorsAsync,
  selectGetDistributorsResponse,
  selectGetDistributorsResponseError,
  selectGetDistributorsResponseRequesting,
  selectGetDistributorsResponseStatus,
} from '../features/api/apiSlice'
import { useEffect } from 'react'

export const DistributorsDropdown = props => {
  const value = props.value
  const click = props.click
  const fill = props.fill || false
  const disabled = props.disabled || false

  const dispatch = useDispatch()

  const distributorsRequesting = useSelector(selectGetDistributorsResponseRequesting)
  const distributorsStatus = useSelector(selectGetDistributorsResponseStatus)
  const distributorsError = useSelector(selectGetDistributorsResponseError)
  const distributors = useSelector(selectGetDistributorsResponse)

  //
  //  :init:
  useEffect(() => {
    if (!distributorsRequesting && !distributorsError && distributorsStatus === 'idle') {
      dispatch(getDistributorsAsync())
    }
  }, [dispatch, distributorsRequesting, distributorsError, distributorsStatus])

  const renderDistributorLanguageCombination = distributor => {
    return `${distributor.uuid}|${distributor.language}`
  }

  const renderDropdown = () => {
    if (!distributors) {
      return
    }
    const options = []
    distributors.map(distributor => {
      const uuidLanguage = renderDistributorLanguageCombination(distributor)
      options.push(
        <option key={uuidLanguage} value={uuidLanguage}>
          {distributor.name}
        </option>
      )
    })

    return (
      <select disabled={disabled} value={value} onChange={e => click(e)} className={_renderClasses()}>
        <option value="-1">Select a distributor</option>
        {options}
      </select>
    )
  }

  const _renderClasses = () => {
    return `text-sm border text-sm rounded-lg block ${
      fill ? 'w-full' : 'w-40'
    } p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500`
  }

  return renderDropdown()
}
