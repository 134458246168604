/*

  r e d i r e c t  i f  n o t  l o g g e d  i n 
  Redirect if not logged in

  :description:

*/

//
//  :react & redux:
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

//
//  :actions:
import { selectLoginIsLoggedIn } from "../features/auth/authSlice";

//
//  :component:
export const RedirectIfNotLoggedIn = (props) => {
  const isLoggedIn = useSelector(selectLoginIsLoggedIn)
  return (
    isLoggedIn === false ? <Navigate to="/login"></Navigate> : <></>
  )
}