/*

  c o n t e n t
  Content

  :description:
  The content component allows for simple control over the "container" element for a given page component.

*/

//
//  :react & redux:
import React from "react";
import { useSelector } from "react-redux";
import { selectLoginIsLoggedIn } from "../features/auth/authSlice";

//
//  :component:
export const Content = (props) => {
  const isLoggedIn = useSelector(selectLoginIsLoggedIn)

  return (
    <div className={`eky-content h-full w-full flex flex-col flex-1 ${isLoggedIn ? 'mt-16' : ''}`}>
      {props.children}
    </div>
  )
}