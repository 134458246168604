/*

  a p i  -  A P I
  api - API

  :description:
  A file to hold our functions that reach out to the API for a response.

  Note, these should all be marshalled through redux actions.

*/

//
//  :react-redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import {
  createConfiguredProduct,
  deleteConfiguredProduct,
  getConfiguredProducts,
  getDistributorsMapping,
  getDistributorProducts,
  getEyekandyProducts,
  getRoomsMapping,
  saveVisualAssetToEyekandyProduct,
  getEyekandyProduct,
  getLocationsMapping,
  getAssets,
  getReport,
  getConfig,
  getDistributors,
  postTriggerUpdateAppConfig,
  postTriggerUpdateProductData,
  postTriggerUpdateProductPrices,
  postTriggerUpdateVisualAssets,
  getRoomPopulationForDistributor,
  postTriggerUpdateProductMapping,
} from './apiAPI'

//
//  :statics:

//
//  :state:
const initialState = {
  getEyekandyProducts: {
    status: 'idle',
    requesting: false,
    error: null,
    response: [],
  },
  getEyekandyProduct: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getConfiguredProducts: {
    status: 'idle',
    requesting: false,
    error: null,
    response: [],
  },
  createConfiguredProduct: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  deleteConfiguredProduct: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },

  getRoomsMapping: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getDistributorsMapping: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getLocationsMapping: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },

  saveVisualAssetToEyekandyProduct: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },

  listAssets: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getReport: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getConfig: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getDistributors: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  getDistributorProducts: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },

  postTriggerUpdateVisualAssets: { status: 'idle', requesting: false, error: null, response: null },
  postTriggerUpdateProductPrices: { status: 'idle', requesting: false, error: null, response: null },
  postTriggerUpdateProductData: { status: 'idle', requesting: false, error: null, response: null },
  postTriggerUpdateAppConfig: { status: 'idle', requesting: false, error: null, response: null },
  postTriggerUpdateMapping: { status: 'idle', requesting: false, error: null, response: null },

  getRoomPopulationForDistributor: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
}

export const getEyekandyProductsAsync = createAsyncThunk('api/get-eyekandy-products', async () => {
  const products = await getEyekandyProducts()
  return products
})

export const getEyekandyProductAsync = createAsyncThunk('api/get-eyekandy-product', async arid => {
  const products = await getEyekandyProduct(arid)
  return products
})

export const getConfiguredProductsAsync = createAsyncThunk('api/get-configured-products', async () => {
  const products = await getConfiguredProducts()
  return products
})

export const createConfiguredProductAsync = createAsyncThunk(
  'api/create-configured-product',
  async configuredProductObject => {
    const created = await createConfiguredProduct(configuredProductObject)
    return created
  }
)

export const deleteConfiguredProductAsync = createAsyncThunk(
  'api/delete-configured-product',
  async configuredProductUuid => {
    const created = await deleteConfiguredProduct(configuredProductUuid)
    return created
  }
)

export const getRoomsMappingAsync = createAsyncThunk('api/get-rooms-mapping', async () => {
  const options = await getRoomsMapping()
  return options
})

export const getLocationsMappingAsync = createAsyncThunk('api/get-locations-mapping', async () => {
  const options = await getLocationsMapping()
  return options
})

export const getDistributorsMappingAsync = createAsyncThunk('api/get-distributors-mapping', async () => {
  const map = await getDistributorsMapping()
  return map
})

export const listAssetsAsync = createAsyncThunk('api/list-assets', async () => {
  const assets = await getAssets()
  return assets
})

export const getReportAsync = createAsyncThunk('api/get-report', async () => {
  const report = await getReport()
  return report
})

export const getConfigAsync = createAsyncThunk('api/get-config', async args => {
  const config = await getConfig(args.distributor, args.language)
  return config
})

export const getDistributorsAsync = createAsyncThunk('api/get-distributors', async () => {
  const distributors = await getDistributors()
  return distributors
})

export const getDistributorProductsAsync = createAsyncThunk('api/get-distributor-products', async args => {
  const products = await getDistributorProducts(args.distributor, args.language)
  return products
})

export const saveVisualAssetToEyekandyProductAsync = createAsyncThunk(
  'api/save-visual-asset-to-eyekandy-product',
  async args => {
    const response = await saveVisualAssetToEyekandyProduct(
      args.type,
      args.arid,
      args.presignedUrl,
      args.room || args.id || null,
      args.location
    )
    return response
  }
)

export const postTriggerUpdateVisualAssetsAsync = createAsyncThunk(
  'api/post-trigger-update-visual-assets',
  async args => {
    const result = await postTriggerUpdateVisualAssets(args.arids, args.roomARIDs)
    return result
  }
)

export const postTriggerUpdateProductPricesAsync = createAsyncThunk(
  'api/post-trigger-update-product-prices',
  async args => {
    const result = await postTriggerUpdateProductPrices(args.distributor, args.language)
    return result
  }
)

export const postTriggerUpdateProductDataAsync = createAsyncThunk(
  'api/post-trigger-update-product-data',
  async args => {
    const result = await postTriggerUpdateProductData(args.distributor, args.language)
    return result
  }
)

export const postTriggerUpdateProductMappingAsync = createAsyncThunk(
  'api/post-trigger-update-product-mapping',
  async args => {
    const result = await postTriggerUpdateProductMapping(args.distributor, args.language)
    return result
  }
)

export const postTriggerUpdateAppConfigAsync = createAsyncThunk('api/post-trigger-update-app-config', async args => {
  const result = await postTriggerUpdateAppConfig(args.distributor, args.language)
  return result
})

export const getRoomPopulationForDistributorAsync = createAsyncThunk(
  'api/get-room-population-for-distributor',
  async args => {
    const population = await getRoomPopulationForDistributor(args.distributor, args.language)
    return population
  }
)

/*
//
//  Now called programatically.
export const getPresignedURLForEyekandyVisualAssetUploadAsync = createAsyncThunk(
  'api/get-presigned-url-for-eyekandy-visual-asset-upload',
  async (args) => {
    const response = await getPresignedURLForEyekandyVisualAssetUpload(args.filename, args.type, args.mime)
    return response
  }
);
*/

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //
      //  :get-eyekandy-products:
      .addCase(getEyekandyProductsAsync.pending, state => {
        state.getEyekandyProducts.status = 'requesting'
        state.getEyekandyProducts.requesting = true
        state.getEyekandyProducts.error = null
      })
      .addCase(getEyekandyProductsAsync.fulfilled, (state, action) => {
        state.getEyekandyProducts.status = 'done'
        state.getEyekandyProducts.requesting = false
        state.getEyekandyProducts.response = action.payload
      })
      .addCase(getEyekandyProductsAsync.rejected, (state, action) => {
        state.getEyekandyProducts.status = 'error'
        state.getEyekandyProducts.requesting = false
        state.getEyekandyProducts.error = action.error
      })

      //
      //  :get-eyekandy-product:
      .addCase(getEyekandyProductAsync.pending, state => {
        state.getEyekandyProduct.status = 'requesting'
        state.getEyekandyProduct.requesting = true
        state.getEyekandyProduct.error = null
      })
      .addCase(getEyekandyProductAsync.fulfilled, (state, action) => {
        state.getEyekandyProduct.status = 'done'
        state.getEyekandyProduct.requesting = false
        state.getEyekandyProduct.response = action.payload
        //
        //  @Louis: Note, here we can upsert this value into our list of eyekandyProducts to keep everything in
        //          sync with the database. But only if we can edit the state from here.
        /*
        for(let i = 0; i < state.getEyekandyProducts.length; i++){
          if(state.getEyekandyProducts[i].arid === action.payload.arid){
            state.getEyekandyProducts[i] = action.payload
            console.error(state.getEyekandyProducts[i])
          }
        }
        */
      })
      .addCase(getEyekandyProductAsync.rejected, (state, action) => {
        state.getEyekandyProduct.status = 'error'
        state.getEyekandyProduct.requesting = false
        state.getEyekandyProduct.error = action.error
      })

      //
      //  :get-configured-products:
      .addCase(getConfiguredProductsAsync.pending, state => {
        state.getConfiguredProducts.status = 'requesting'
        state.getConfiguredProducts.requesting = true
        state.getConfiguredProducts.error = null
        //state.getConfiguredProducts.response = null
      })
      .addCase(getConfiguredProductsAsync.fulfilled, (state, action) => {
        state.getConfiguredProducts.status = 'done'
        state.getConfiguredProducts.requesting = false
        state.getConfiguredProducts.response = action.payload
      })
      .addCase(getConfiguredProductsAsync.rejected, (state, action) => {
        state.getConfiguredProducts.status = 'error'
        state.getConfiguredProducts.requesting = false
        state.getConfiguredProducts.error = action.error
      })

      //
      //  :create-configured-product:
      .addCase(createConfiguredProductAsync.pending, state => {
        state.createConfiguredProduct.status = 'requesting'
        state.createConfiguredProduct.requesting = true
        state.createConfiguredProduct.error = null
        state.createConfiguredProduct.response = null
      })
      .addCase(createConfiguredProductAsync.fulfilled, (state, action) => {
        state.createConfiguredProduct.status = 'done'
        state.createConfiguredProduct.requesting = false
        state.createConfiguredProduct.response = action.payload
      })
      .addCase(createConfiguredProductAsync.rejected, (state, action) => {
        state.createConfiguredProduct.status = 'error'
        state.createConfiguredProduct.requesting = false
        state.createConfiguredProduct.error = action.error
      })

      //
      //  :delete-configured-product:
      .addCase(deleteConfiguredProductAsync.pending, state => {
        state.deleteConfiguredProduct.status = 'requesting'
        state.deleteConfiguredProduct.requesting = true
        state.deleteConfiguredProduct.error = null
        state.deleteConfiguredProduct.response = null
      })
      .addCase(deleteConfiguredProductAsync.fulfilled, (state, action) => {
        state.deleteConfiguredProduct.status = 'done'
        state.deleteConfiguredProduct.requesting = false
        state.deleteConfiguredProduct.response = action.payload
      })
      .addCase(deleteConfiguredProductAsync.rejected, (state, action) => {
        state.deleteConfiguredProduct.status = 'error'
        state.deleteConfiguredProduct.requesting = false
        state.deleteConfiguredProduct.error = action.error
      })

      //
      //  :get-room-enum-options:
      .addCase(getRoomsMappingAsync.pending, state => {
        state.getRoomsMapping.status = 'requesting'
        state.getRoomsMapping.requesting = true
        state.getRoomsMapping.error = null
      })
      .addCase(getRoomsMappingAsync.fulfilled, (state, action) => {
        state.getRoomsMapping.status = 'done'
        state.getRoomsMapping.requesting = false
        state.getRoomsMapping.response = action.payload
      })
      .addCase(getRoomsMappingAsync.rejected, (state, action) => {
        state.getRoomsMapping.status = 'error'
        state.getRoomsMapping.requesting = false
        state.getRoomsMapping.error = action.error
      })

      //
      //  :get-distributor-mapping:
      .addCase(getDistributorsMappingAsync.pending, state => {
        state.getDistributorsMapping.status = 'requesting'
        state.getDistributorsMapping.requesting = true
        state.getDistributorsMapping.error = null
      })
      .addCase(getDistributorsMappingAsync.fulfilled, (state, action) => {
        state.getDistributorsMapping.status = 'done'
        state.getDistributorsMapping.requesting = false
        state.getDistributorsMapping.response = action.payload
      })
      .addCase(getDistributorsMappingAsync.rejected, (state, action) => {
        state.getDistributorsMapping.status = 'error'
        state.getDistributorsMapping.requesting = false
        state.getDistributorsMapping.error = action.error
      })

      //
      //  :get-locations-mapping:
      .addCase(getLocationsMappingAsync.pending, state => {
        state.getLocationsMapping.status = 'requesting'
        state.getLocationsMapping.requesting = true
        state.getLocationsMapping.error = null
      })
      .addCase(getLocationsMappingAsync.fulfilled, (state, action) => {
        state.getLocationsMapping.status = 'done'
        state.getLocationsMapping.requesting = false
        state.getLocationsMapping.response = action.payload
      })
      .addCase(getLocationsMappingAsync.rejected, (state, action) => {
        state.getLocationsMapping.status = 'error'
        state.getLocationsMapping.requesting = false
        state.getLocationsMapping.error = action.error
      })

      //
      //  :save-visual-asset-to-eyekandy-product:
      .addCase(saveVisualAssetToEyekandyProductAsync.pending, state => {
        state.saveVisualAssetToEyekandyProduct.status = 'requesting'
        state.saveVisualAssetToEyekandyProduct.requesting = true
        state.saveVisualAssetToEyekandyProduct.error = null
      })
      .addCase(saveVisualAssetToEyekandyProductAsync.fulfilled, (state, action) => {
        state.saveVisualAssetToEyekandyProduct.status = 'done'
        state.saveVisualAssetToEyekandyProduct.requesting = false
        state.saveVisualAssetToEyekandyProduct.response = action.payload
      })
      .addCase(saveVisualAssetToEyekandyProductAsync.rejected, (state, action) => {
        state.saveVisualAssetToEyekandyProduct.status = 'error'
        state.saveVisualAssetToEyekandyProduct.requesting = false
        state.saveVisualAssetToEyekandyProduct.error = action.error
        console.error(action.error)
      })

      //
      //  :list-assets:
      .addCase(listAssetsAsync.pending, state => {
        state.listAssets.status = 'requesting'
        state.listAssets.requesting = true
        state.listAssets.error = null
      })
      .addCase(listAssetsAsync.fulfilled, (state, action) => {
        state.listAssets.status = 'done'
        state.listAssets.requesting = false
        state.listAssets.response = action.payload
      })
      .addCase(listAssetsAsync.rejected, (state, action) => {
        state.listAssets.status = 'error'
        state.listAssets.requesting = false
        state.listAssets.error = action.error
      })

      //
      //  :report:
      .addCase(getReportAsync.pending, state => {
        state.getReport.status = 'requesting'
        state.getReport.requesting = true
        state.getReport.error = null
      })
      .addCase(getReportAsync.fulfilled, (state, action) => {
        state.getReport.status = 'done'
        state.getReport.requesting = false
        state.getReport.response = action.payload
      })
      .addCase(getReportAsync.rejected, (state, action) => {
        state.getReport.status = 'error'
        state.getReport.requesting = false
        state.getReport.error = action.error
      })

      //
      //  :config:
      .addCase(getConfigAsync.pending, state => {
        state.getConfig.status = 'requesting'
        state.getConfig.requesting = true
        state.getConfig.error = null
      })
      .addCase(getConfigAsync.fulfilled, (state, action) => {
        state.getConfig.status = 'done'
        state.getConfig.requesting = false
        state.getConfig.response = action.payload
      })
      .addCase(getConfigAsync.rejected, (state, action) => {
        state.getConfig.status = 'error'
        state.getConfig.requesting = false
        state.getConfig.error = action.error
      })

      //
      //  :get-distributors:
      .addCase(getDistributorsAsync.pending, state => {
        state.getDistributors.status = 'requesting'
        state.getDistributors.requesting = true
        state.getDistributors.error = null
      })
      .addCase(getDistributorsAsync.fulfilled, (state, action) => {
        state.getDistributors.status = 'done'
        state.getDistributors.requesting = false
        state.getDistributors.response = action.payload
      })
      .addCase(getDistributorsAsync.rejected, (state, action) => {
        state.getDistributors.status = 'error'
        state.getDistributors.requesting = false
        state.getDistributors.error = action.error
      })

      //
      //  :get-distributor-products:
      .addCase(getDistributorProductsAsync.pending, state => {
        state.getDistributorProducts.status = 'requesting'
        state.getDistributorProducts.requesting = true
        state.getDistributorProducts.error = null
        state.getDistributorProducts.response = []
      })
      .addCase(getDistributorProductsAsync.fulfilled, (state, action) => {
        state.getDistributorProducts.status = 'done'
        state.getDistributorProducts.requesting = false
        state.getDistributorProducts.response = action.payload
      })
      .addCase(getDistributorProductsAsync.rejected, (state, action) => {
        state.getDistributorProducts.status = 'error'
        state.getDistributorProducts.requesting = false
        state.getDistributorProducts.error = action.error
      })

      //
      //  :post-trigger-update-visual-assets:
      .addCase(postTriggerUpdateVisualAssetsAsync.pending, state => {
        state.postTriggerUpdateVisualAssets.status = 'requesting'
        state.postTriggerUpdateVisualAssets.requesting = true
        state.postTriggerUpdateVisualAssets.error = null
        state.postTriggerUpdateVisualAssets.response = {}
      })
      .addCase(postTriggerUpdateVisualAssetsAsync.fulfilled, (state, action) => {
        state.postTriggerUpdateVisualAssets.status = 'done'
        state.postTriggerUpdateVisualAssets.requesting = false
        state.postTriggerUpdateVisualAssets.response = action.payload
      })
      .addCase(postTriggerUpdateVisualAssetsAsync.rejected, (state, action) => {
        state.postTriggerUpdateVisualAssets.status = 'error'
        state.postTriggerUpdateVisualAssets.requesting = false
        state.postTriggerUpdateVisualAssets.error = action.error
      })

      //
      //  :post-trigger-update-product-data:
      .addCase(postTriggerUpdateProductDataAsync.pending, state => {
        state.postTriggerUpdateProductData.status = 'requesting'
        state.postTriggerUpdateProductData.requesting = true
        state.postTriggerUpdateProductData.error = null
        state.postTriggerUpdateProductData.response = {}
      })
      .addCase(postTriggerUpdateProductDataAsync.fulfilled, (state, action) => {
        state.postTriggerUpdateProductData.status = 'done'
        state.postTriggerUpdateProductData.requesting = false
        state.postTriggerUpdateProductData.response = action.payload
      })
      .addCase(postTriggerUpdateProductDataAsync.rejected, (state, action) => {
        state.postTriggerUpdateProductData.status = 'error'
        state.postTriggerUpdateProductData.requesting = false
        state.postTriggerUpdateProductData.error = action.error
      })

      //
      //  :post-trigger-update-product-prices:
      .addCase(postTriggerUpdateProductPricesAsync.pending, state => {
        state.postTriggerUpdateProductPrices.status = 'requesting'
        state.postTriggerUpdateProductPrices.requesting = true
        state.postTriggerUpdateProductPrices.error = null
        state.postTriggerUpdateProductPrices.response = {}
      })
      .addCase(postTriggerUpdateProductPricesAsync.fulfilled, (state, action) => {
        state.postTriggerUpdateProductPrices.status = 'done'
        state.postTriggerUpdateProductPrices.requesting = false
        state.postTriggerUpdateProductPrices.response = action.payload
      })
      .addCase(postTriggerUpdateProductPricesAsync.rejected, (state, action) => {
        state.postTriggerUpdateProductPrices.status = 'error'
        state.postTriggerUpdateProductPrices.requesting = false
        state.postTriggerUpdateProductPrices.error = action.error
      })

      //
      //  :post-trigger-update-app-config:
      .addCase(postTriggerUpdateAppConfigAsync.pending, state => {
        state.postTriggerUpdateAppConfig.status = 'requesting'
        state.postTriggerUpdateAppConfig.requesting = true
        state.postTriggerUpdateAppConfig.error = null
        state.postTriggerUpdateAppConfig.response = {}
      })
      .addCase(postTriggerUpdateAppConfigAsync.fulfilled, (state, action) => {
        state.postTriggerUpdateAppConfig.status = 'done'
        state.postTriggerUpdateAppConfig.requesting = false
        state.postTriggerUpdateAppConfig.response = action.payload
      })
      .addCase(postTriggerUpdateAppConfigAsync.rejected, (state, action) => {
        state.postTriggerUpdateAppConfig.status = 'error'
        state.postTriggerUpdateAppConfig.requesting = false
        state.postTriggerUpdateAppConfig.error = action.error
      })

      //
      //  :post-trigger-update-product-mapping:
      .addCase(postTriggerUpdateProductMappingAsync.pending, state => {
        state.postTriggerUpdateMapping.status = 'requesting'
        state.postTriggerUpdateMapping.requesting = true
        state.postTriggerUpdateMapping.error = null
        state.postTriggerUpdateMapping.response = {}
      })
      .addCase(postTriggerUpdateProductMappingAsync.fulfilled, (state, action) => {
        state.postTriggerUpdateMapping.status = 'done'
        state.postTriggerUpdateMapping.requesting = false
        state.postTriggerUpdateMapping.response = action.payload
      })
      .addCase(postTriggerUpdateProductMappingAsync.rejected, (state, action) => {
        state.postTriggerUpdateMapping.status = 'error'
        state.postTriggerUpdateMapping.requesting = false
        state.postTriggerUpdateMapping.error = action.error
      })

      //
      //  :get-room-population-for-distributor:
      .addCase(getRoomPopulationForDistributorAsync.pending, state => {
        state.getRoomPopulationForDistributor.status = 'requesting'
        state.getRoomPopulationForDistributor.requesting = true
        state.getRoomPopulationForDistributor.error = null
        state.getRoomPopulationForDistributor.response = []
      })
      .addCase(getRoomPopulationForDistributorAsync.fulfilled, (state, action) => {
        state.getRoomPopulationForDistributor.status = 'done'
        state.getRoomPopulationForDistributor.requesting = false
        state.getRoomPopulationForDistributor.response = action.payload
      })
      .addCase(getRoomPopulationForDistributorAsync.rejected, (state, action) => {
        state.getRoomPopulationForDistributor.status = 'error'
        state.getRoomPopulationForDistributor.requesting = false
        state.getRoomPopulationForDistributor.error = action.error
      })
  },
})

//export const {  } = apiSlice.actions;

//
//  :get-eyekandy-products:
export const selectGetEyekandyProductsStatus = state => state.api.getEyekandyProducts.status
export const selectGetEyekandyProductsRequesting = state => state.api.getEyekandyProducts.requesting
export const selectGetEyekandyProductsError = state => state.api.getEyekandyProducts.error
export const selectGetEyekandyProductsResponse = state => state.api.getEyekandyProducts.response

//
//  :get-eyekandy-product:
export const selectGetEyekandyProductStatus = state => state.api.getEyekandyProduct.status
export const selectGetEyekandyProductRequesting = state => state.api.getEyekandyProduct.requesting
export const selectGetEyekandyProductError = state => state.api.getEyekandyProduct.error
export const selectGetEyekandyProductResponse = state => state.api.getEyekandyProduct.response

//
//  :get-distributor-products:
export const selectGetDistributorProductsStatus = state => state.api.getDistributorProducts.status
export const selectGetDistributorProductsRequesting = state => state.api.getDistributorProducts.requesting
export const selectGetDistributorProductsError = state => state.api.getDistributorProducts.error
export const selectGetDistributorProductsResponse = state => state.api.getDistributorProducts.response

//
//  :get-configured-products:
export const selectGetConfiguredProductsStatus = state => state.api.getConfiguredProducts.status
export const selectGetConfiguredProductsRequesting = state => state.api.getConfiguredProducts.requesting
export const selectGetConfiguredProductsError = state => state.api.getConfiguredProducts.error
export const selectGetConfiguredProductsResponse = state => state.api.getConfiguredProducts.response

//
//  :create-configured-product:
export const selectCreateConfiguredProductStatus = state => state.api.createConfiguredProduct.status
export const selectCreateConfiguredProductRequesting = state => state.api.createConfiguredProduct.requesting
export const selectCreateConfiguredProductError = state => state.api.createConfiguredProduct.error
export const selectCreateConfiguredProductResponse = state => state.api.createConfiguredProduct.response

//
//  :create-configured-product:
export const selectDeleteConfiguredProductStatus = state => state.api.deleteConfiguredProduct.status
export const selectDeleteConfiguredProductRequesting = state => state.api.deleteConfiguredProduct.requesting
export const selectDeleteConfiguredProductError = state => state.api.deleteConfiguredProduct.error
export const selectDeleteConfiguredProductResponse = state => state.api.deleteConfiguredProduct.response

//
//  :get-room-enum-options:
export const selectGetRoomsMappingStatus = state => state.api.getRoomsMapping.status
export const selectGetRoomsMappingRequesting = state => state.api.getRoomsMapping.requesting
export const selectGetRoomsMappingError = state => state.api.getRoomsMapping.error
export const selectGetRoomsMappingResponse = state => state.api.getRoomsMapping.response

//
//  :get-distributor-mapping:
export const selectGetDistributorsMappingStatus = state => state.api.getDistributorsMapping.status
export const selectGetDistributorsMappingRequesting = state => state.api.getDistributorsMapping.requesting
export const selectGetDistributorsMappingError = state => state.api.getDistributorsMapping.error
export const selectGetDistributorsMappingResponse = state => state.api.getDistributorsMapping.response

//
//  :get-locations-mapping:
export const selectGetLocationsMappingStatus = state => state.api.getLocationsMapping.status
export const selectGetLocationsMappingRequesting = state => state.api.getLocationsMapping.requesting
export const selectGetLocationsMappingError = state => state.api.getLocationsMapping.error
export const selectGetLocationsMappingResponse = state => state.api.getLocationsMapping.response

//
//  :save-visual-asset-to-eyekandy-product:
export const selectSaveVisualAssetToEyekandyProductStatus = state => state.api.saveVisualAssetToEyekandyProduct.status
export const selectSaveVisualAssetToEyekandyProductRequesting = state =>
  state.api.saveVisualAssetToEyekandyProduct.requesting
export const selectSaveVisualAssetToEyekandyProductError = state => state.api.saveVisualAssetToEyekandyProduct.error
export const selectSaveVisualAssetToEyekandyProductResponse = state =>
  state.api.saveVisualAssetToEyekandyProduct.response

//
//  :list-assets:
export const selectListAssetsResponseStatus = state => state.api.listAssets.status
export const selectListAssetsResponseRequesting = state => state.api.listAssets.requesting
export const selectListAssetsResponseError = state => state.api.listAssets.error
export const selectListAssetsResponseResponse = state => state.api.listAssets.response

//
//  :get-report:
export const selectGetReportResponseStatus = state => state.api.getReport.status
export const selectGetReportResponseRequesting = state => state.api.getReport.requesting
export const selectGetRportResponseError = state => state.api.getReport.error
export const selectGetReportResponseResponse = state => state.api.getReport.response

//
//  :get-config:
export const selectGetConfigResponseStatus = state => state.api.getConfig.status
export const selectGetConfigResponseRequesting = state => state.api.getConfig.requesting
export const selectGetConfigResponseError = state => state.api.getConfig.error
export const selectGetConfigResponseResponse = state => state.api.getConfig.response

//
//  :get-distributors:
export const selectGetDistributorsResponseStatus = state => state.api.getDistributors.status
export const selectGetDistributorsResponseRequesting = state => state.api.getDistributors.requesting
export const selectGetDistributorsResponseError = state => state.api.getDistributors.error
export const selectGetDistributorsResponse = state => state.api.getDistributors.response

//
//  :post-trigger-update-visual-assets:
export const selectPostTriggerUpdateVisualAssetsResponseStatus = state => state.api.postTriggerUpdateVisualAssets.status
export const selectPostTriggerUpdateVisualAssetsResponseRequesting = state =>
  state.api.postTriggerUpdateVisualAssets.requesting
export const selectPostTriggerUpdateVisualAssetsResponseError = state => state.api.postTriggerUpdateVisualAssets.error
export const selectPostTriggerUpdateVisualAssetsResponse = state => state.api.postTriggerUpdateVisualAssets.response

//
//  :post-trigger-update-product-prices:
export const selectPostTriggerUpdateProductPricesResponseStatus = state =>
  state.api.postTriggerUpdateProductPrices.status
export const selectPostTriggerUpdateProductPricesResponseRequesting = state =>
  state.api.postTriggerUpdateProductPrices.requesting
export const selectPostTriggerUpdateProductPricesResponseError = state => state.api.postTriggerUpdateProductPrices.error
export const selectPostTriggerUpdateProductPricesResponse = state => state.api.postTriggerUpdateProductPrices.response

//
//  :post-trigger-update-product-data:
export const selectPostTriggerUpdateProductDataResponseStatus = state => state.api.postTriggerUpdateProductData.status
export const selectPostTriggerUpdateProductDataResponseRequesting = state =>
  state.api.postTriggerUpdateProductData.requesting
export const selectPostTriggerUpdateProductDataResponseError = state => state.api.postTriggerUpdateProductData.error
export const selectPostTriggerUpdateProductDataResponse = state => state.api.postTriggerUpdateProductData.response

//
//  :post-trigger-update-product-mapping:
export const selectPostTriggerUpdateProductMappingResponseStatus = state =>
  state.api.postTriggerUpdateProductMapping.status
export const selectPostTriggerUpdateProductMappingResponseRequesting = state =>
  state.api.postTriggerUpdateProductMapping.requesting
export const selectPostTriggerUpdateProductMappingResponseError = state =>
  state.api.postTriggerUpdateProductMapping.error
export const selectPostTriggerUpdateProductMappingResponse = state => state.api.postTriggerUpdateProductMapping.response

//
//  :post-trigger-update-app-config:
export const selectPostTriggerUpdateAppConfigResponseStatus = state => state.api.postTriggerUpdateAppConfig.status
export const selectPostTriggerUpdateAppConfigResponseRequesting = state =>
  state.api.postTriggerUpdateAppConfig.requesting
export const selectPostTriggerUpdateAppConfigResponseError = state => state.api.postTriggerUpdateAppConfig.error
export const selectPostTriggerUpdateAppConfigResponse = state => state.api.postTriggerUpdateAppConfig.response

//
//  :get-room-population-for-distributor:
export const selectGetRoomPopulationForDistributorResponseStatus = state =>
  state.api.getRoomPopulationForDistributor.status
export const selectGetRoomPopulationForDistributorResponseRequesting = state =>
  state.api.getRoomPopulationForDistributor.requesting
export const selectGetRoomPopulationForDistributorResponseError = state =>
  state.api.getRoomPopulationForDistributor.error
export const selectGetRoomPopulationForDistributorResponse = state => state.api.getRoomPopulationForDistributor.response

//
//  :exports:
export default apiSlice.reducer
