import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//
//  :pages:
import { Layout } from './components/Layout'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { NotFound } from './pages/NotFound'
import { ProductData } from './pages/upload/ProductData'
import { ProductPrices } from './pages/upload/ProductPrices'
import { UploadTextures } from './pages/upload/Textures'
import { UploadThumbnails } from './pages/upload/Thumbnails'
import { ProductMapping } from './pages/upload/ProductMapping'
import { Report } from './pages/qa/Report'
import { Viewer } from './pages/qa/Viewer'
import { List } from './pages/qa/List'

const container = document.getElementById('root')
const root = createRoot(container)

//
//  Note, removed encasing <React.Strict>.
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />

          <Route path="upload/textures/:room" element={<UploadTextures></UploadTextures>} />
          <Route path="upload/thumbnails" element={<UploadThumbnails></UploadThumbnails>} />

          <Route path="upload/products" element={<ProductData></ProductData>} />
          <Route path="upload/prices" element={<ProductPrices></ProductPrices>} />
          <Route path="upload/mappings" element={<ProductMapping></ProductMapping>} />

          <Route path="qa/report" element={<Report></Report>} />
          <Route path="qa/viewer" element={<Viewer></Viewer>} />
          <Route path="qa/list" element={<List></List>} />

          <Route path="config/product-mapping" element={<ProductMapping></ProductMapping>} />
        </Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
