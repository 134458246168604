import axios from 'axios'
import {
  getPresignedURLForEyekandyFileUploadv2,
  getPresignedURLForEyekandyProductDataUploadv2,
  getPresignedURLForEyekandyProductMappingUploadv2,
  getPresignedURLForEyekandyProductPricesUploadv2,
  getPresignedURLForEyekandyVisualAssetUpload,
  getPresignedURLForEyekandyVisualAssetUploadv2,
} from '../api/apiAPI'

//
//  :helpers:

const readContentTypeFromFilename = filename => {
  if (!filename) {
    return null
  }
  if (filename.endsWith('.png')) {
    return 'image/png'
  }
  if (filename.endsWith('.jpeg')) {
    return 'image/jpeg'
  }
  if (filename.endsWith('.jpg')) {
    return 'image/jpeg'
  }
}

export const uploadFileToDestination = async (file, destination) => {
  //
  //  Read the content type from the file.
  const contentType = readContentTypeFromFilename(file.name)
  //
  //  Send the file to s3 from here.
  const axiosOptions = { headers: { 'Content-Type': contentType } }
  // eslint-disable-next-line
  const response = await axios.put(destination, file, axiosOptions)
  //
  //  Ok, return true as the file was uploaded.
  return true
}

export const uploadFileToDestinationv2 = async (file, destination) => {
  //
  //  Read the content type from the file.
  // eslint-disable-next-line
  const contentType = readContentTypeFromFilename(file.name)
  //
  //  Send the file to s3 from here.
  const axiosOptions = { headers: { 'Content-Type': 'application/octet-stream' } }
  // eslint-disable-next-line
  const response = await axios.put(destination, file, axiosOptions)
  //
  //  Ok, return true as the file was uploaded.
  return true
}

export const _uploadVisualAsset = async (file, type, arid, extension, mime, roomOrId, location) => {
  //
  //  :step 1:
  //  We need to request the presigned url for the file, this request is made to our API.
  const response = await getPresignedURLForEyekandyVisualAssetUpload(type, arid, extension, mime, roomOrId, location)
  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for _uploadVisualAsset()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for _uploadVisualAsset()`)
  }
  const presignedUrl = response.url
  //
  //  :step 2:
  //  We need to now PUT this file on s3 ourselves.
  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }
  return presignedUrl
}

//
//  :called-by-slice:
export const uploadLogo = async (file, arid, extension, mime) => {
  return _uploadVisualAsset(file, 'logo', arid, extension, mime)
}

export const uploadImage = async (file, arid, extension, mime, id) => {
  return _uploadVisualAsset(file, 'image', arid, extension, mime, id)
}

export const uploadTexture = async (file, arid, extension, mime, room, location) => {
  return _uploadVisualAsset(file, 'texture', arid, extension, mime, room, location)
}

export const uploadThumbnail = async (file, arid, extension, mime, room, location) => {
  return _uploadVisualAsset(file, 'thumbnail', arid, extension, mime, room, location)
}

export const uploadAsset = async (type, room, file) => {
  //
  //  :step 1:
  //  Break out the data from our File object into variables.
  const name = file.name
  const extension = name.split('.')[name.split('.').length - 1]
  const arid = file.name.match(/\d{6,16}/)[0]

  //
  //
  //  :step 2:
  //  Get the presigned URL for uploading this file.
  const response = await getPresignedURLForEyekandyVisualAssetUploadv2(type, room, arid, extension)
  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for uploadAsset()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for uploadAsset()`)
  }
  const presignedUrl = response.url

  //
  //
  //  :step 3:
  //  Ok, now send the data to s3 directly using the presigned URL.
  console.log(file, presignedUrl)
  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }

  //
  //
  //  :step 4:
  //  All done, return true as we did manage to upload the file.
  return true
}

export const uploadFile = async (type, file) => {
  //
  //  :step 1:
  //  Break out the data from our File object into variables.
  const name = file.name
  const extension = name.split('.')[name.split('.').length - 1]
  const arid = file.name.match(/\d{6,16}/)[0]

  //
  //
  //  :step 2:
  //  Get the presigned URL for uploading this file.
  const response = await getPresignedURLForEyekandyFileUploadv2(type, arid, extension)
  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for uploadFile()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for uploadFile()`)
  }
  const presignedUrl = response.url

  //
  //
  //  :step 3:
  //  Ok, now send the data to s3 directly using the presigned URL.
  console.log(file, presignedUrl)
  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }

  //
  //
  //  :step 4:
  //  All done, return true as we did manage to upload the file.
  return true
}

export const uploadProductData = async (distributor, language, file) => {
  //
  //  :step 1:
  //  Break out the data from our File object into variables.
  const name = file.name
  const extension = name.split('.')[name.split('.').length - 1]

  //
  //
  //  :step 2:
  //  Get the presigned URL for uploading this file.
  const response = await getPresignedURLForEyekandyProductDataUploadv2(distributor, language, extension)
  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for uploadProductData()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for uploadProductData()`)
  }
  const presignedUrl = response.url

  //
  //
  //  :step 3:
  //  Ok, now send the data to s3 directly using the presigned URL.
  console.log(file, presignedUrl)
  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }

  //
  //
  //  :step 4:
  //  All done, return true as we did manage to upload the file.
  return true
}

export const uploadProductPrices = async (distributor, language, file) => {
  //
  //  :step 1:
  //  Break out the data from our File object into variables.
  const name = file.name
  const extension = name.split('.')[name.split('.').length - 1]

  //
  //
  //  :step 2:
  //  Get the presigned URL for uploading this file.
  const response = await getPresignedURLForEyekandyProductPricesUploadv2(distributor, language, extension)
  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for uploadProductPrices()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for uploadProductPrices()`)
  }
  const presignedUrl = response.url

  //
  //
  //  :step 3:
  //  Ok, now send the data to s3 directly using the presigned URL.
  console.log(file, presignedUrl)
  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }

  //
  //
  //  :step 4:
  //  All done, return true as we did manage to upload the file.
  return true
}

export const uploadProductMappings = async (distributor, language, file) => {
  //
  //  :step 1:
  //  Break out the data from our File object into variables.
  const name = file.name
  const extension = name.split('.')[name.split('.').length - 1]

  //
  //
  //  :step 2:
  //  Get the presigned URL for uploading this file.
  const response = await getPresignedURLForEyekandyProductMappingUploadv2(distributor, language, extension)
  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for uploadProductMappings()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for uploadProductMappings()`)
  }
  const presignedUrl = response.url

  //
  //
  //  :step 3:
  //  Ok, now send the data to s3 directly using the presigned URL.
  console.log(file, presignedUrl)
  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }

  //
  //
  //  :step 4:
  //  All done, return true as we did manage to upload the file.
  return true
}
