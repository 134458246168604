/*

  n a v i g a t i o n
  Navigation

  :description:
  A component to represent the navigation bar.

*/

//
//  :react & redux:
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { logout, selectLoginIsLoggedIn } from '../features/auth/authSlice'

//
//  :component:
export const Navigation = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isLoggedIn = useSelector(selectLoginIsLoggedIn)

  const isActive = (pageName, isHome) => {
    let pageNameIsActive = false
    if (isHome && location.pathname === '/') {
      pageNameIsActive = true
    }
    if (location.pathname.startsWith(pageName) && !isHome) {
      pageNameIsActive = true
    }
    return pageNameIsActive ? 'bg-green-700' : ''
  }

  const onClickLogout = async event => {
    await dispatch(logout())
  }

  if (!isLoggedIn) {
    return
  }

  return (
    <div className="eky-navigation w-full h-16 flex justify-between items-center bg-blue-500 pl-4 fixed z-20">
      <Link className="p-2" to="/">
        <div>
          <h1 className="text-2xl">eyekandy</h1>
          <p>pc rooms ui</p>
        </div>
      </Link>
      <div className="flex h-full justify-between">
        {/* 
        <div className={`h-full items-center flex px-2 ${isActive('/', true)}`}>
          <Link className="p-2" to="/">
            home
          </Link>
        </div>
      
        <div className={`h-full items-center flex px-2 ${isActive('/products')}`}>
          <Link className="p-2" to="/products">
            products
          </Link>
        </div>
        
        <div className={`h-full items-center flex px-2 ${isActive('/assets')}`}>
          <Link className="p-2" to="/assets">
            assets
          </Link>
        </div>
*/}
        <div onClick={onClickLogout} className={`h-full items-center flex px-2 ${isActive('/logout')} bg-orange-800`}>
          <Link className="p-2" to="/login">
            logout
          </Link>
        </div>
      </div>
    </div>
  )
}
