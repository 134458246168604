/*

  l o g i n
  Login

  :description:
  The Login page.

*/

//
//  :react & redux:
import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//
//  :components:
import { Content } from '../components/Content'
import { RedirectIfLoggedIn } from '../components/RedirectIfLoggedIn'
import { loginAsync, selectLoginError, selectLoginRequesting } from '../features/auth/authSlice'

//
//  :component:
export const Login = props => {
  const dispatch = useDispatch()

  //const username = useSelector(selectLoginUsername)
  const requesting = useSelector(selectLoginRequesting)
  const error = useSelector(selectLoginError)
  //const response = useSelector(selectLoginResponse)

  const [usernameInput, setUsernameInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')

  const onClickLogin = async event => {
    event.preventDefault()
    await dispatch(loginAsync({ username: usernameInput, password: passwordInput }))
  }

  return (
    <>
      <RedirectIfLoggedIn />

      <Content>
        <div className="w-full h-full flex flex-1">
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center ">
            <h1 className="text-5xl py-2">login</h1>
            <span className="">eyekandy pc rooms</span>

            <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
              <div className="w-48 ">
                <form className="mt-4" action="#" method="POST">
                  <input type="hidden" name="remember" value="true" />
                  <div className="rounded-md shadow-sm h-100">
                    <div>
                      <label htmlFor="username" className="sr-only">
                        Username
                      </label>
                      <input
                        value={usernameInput}
                        onChange={e => setUsernameInput(e.target.value)}
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Username"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        value={passwordInput}
                        onChange={e => setPasswordInput(e.target.value)}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative w-full mt-4 flex h-9 justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={onClickLogin}
                    >
                      {requesting === false ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                          <path style={{ fill: '#ffffff' }} d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                        </svg>
                      ) : (
                        '...'
                      )}
                    </button>

                    {error && (
                      <p className="flex-1 text-sm font-mono text-center bg-red-400 text-red-900 max-w-md w-full mt-4 p-2 rounded-lg">
                        {error.message}
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="w-1/2 hidden md:flex">
            <img
              alt=""
              onLoad={e => {
                e.target.classList.remove('opacity-0')
              }}
              className={`transition-opacity duration-700 w-full h-full object-cover opacity-0`}
              src="https://source.unsplash.com/2560x1440/?landscape"
            />
          </div>
        </div>
      </Content>
    </>
  )
}
