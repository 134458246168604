/*

  x
  X

  :description

*/

//
//  :react & redux:
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Content } from '../../components/Content'
import { RedirectIfNotLoggedIn } from '../../components/RedirectIfNotLoggedIn'
import { useDispatch } from 'react-redux'
import { uploadAssetAsync } from '../../features/upload-to-s3/uploadToS3Slice'
import { postTriggerUpdateVisualAssetsAsync } from '../../features/api/apiSlice'

//
//  :components:

//
//  :component:
export const UploadTextures = props => {
  const { room } = useParams()
  const type = 'textures'

  const roomImages = {
    professional: 'https://cdn.pointandplace.com/services/pc-rooms/rooms/professional.jpg',
    gaming: 'https://cdn.pointandplace.com/services/pc-rooms/rooms/gaming.jpg',
    student: 'https://cdn.pointandplace.com/services/pc-rooms/rooms/student.jpg',
    remote: 'https://cdn.pointandplace.com/services/pc-rooms/rooms/remote.jpg',
    editor: 'https://cdn.pointandplace.com/services/pc-rooms/rooms/editor.jpg',
  }

  const [filesToUpload, setFilesToUpload] = useState({})
  const [invalidFiles, setInvalidFiles] = useState({})

  //
  //  :state:
  const dispatch = useDispatch()

  const onClickClear = () => {
    setFilesToUpload({})
    setInvalidFiles({})
    //
    //  TODO: Get a handle on the <input> element.
    window.location.reload()
  }

  const onClickClearErrors = () => {
    setInvalidFiles({})
  }

  const validateFileForUpload = file => {
    //
    //  :step 1:
    //  The file must have an ARID in the file name.
    if (!file.name.match(/\d{6,16}/)) {
      return false
    }

    //
    //  Ok, the file must be valid, return true.
    return true
  }

  const onChangeUploadField = event => {
    //
    //  :step 1:
    //  Get a handle on our input field.
    const field = event.target

    //
    //  :step 2:
    //  If there were files, we need to save them into our files array.
    const files = Array.from(field.files)
    const processedFiles = []
    files.map(file => {
      //
      //  Now validate this file.
      const isValid = validateFileForUpload(file)

      //
      //  If the file is valid, add to files to upload.
      processedFiles.push({
        valid: isValid,
        file: file,
      })
      return true
    })

    //
    //  Ok, now update our React state.
    let newValidFiles = {}
    let newInvalidFiles = {}
    processedFiles.map(element => {
      let target = element.valid ? newValidFiles : newInvalidFiles
      target[element.file.name] = element.file
      return true
    })

    //
    //  If there are changes, update our local state.
    if (Object.keys(newValidFiles).length > 0) {
      setFilesToUpload({ ...filesToUpload, ...newValidFiles })
    }
    if (Object.keys(newInvalidFiles).length > 0) {
      setInvalidFiles({ ...invalidFiles, ...newInvalidFiles })
    }

    //
    //  OK, now clear the UI of the file upload element.
    field.value = ''

    //
    //  All done, we have processed the files given to us, but not actually uploaded anything.
    return true
  }

  const uploadAllValidFiles = async event => {
    //
    //  :step 1:
    //  Just handle our upload sequence here.
    const work = []
    const roomARIDs = []
    // eslint-disable-next-line
    for (const [k, v] of Object.entries(filesToUpload)) {
      work.push({
        type: type,
        room: room,
        file: v,
      })
      const arid = v.name.match(/\d{6,16}/)[0]
      roomARIDs.push(`${room}|${arid}`)
    }
    await Promise.all(
      work.map(task => {
        return dispatch(uploadAssetAsync(task))
      })
    )

    //
    //  :step 2:
    //  We just added some files to the cloud, they'll need to be optimised/published before they can be used.
    //  Just trigger the lambda to ingest them.
    await dispatch(postTriggerUpdateVisualAssetsAsync({ roomARIDs: roomARIDs }))

    //
    //  Ok, now clear our UI.
    setFilesToUpload({})
    setInvalidFiles({})
  }

  const renderUploadUI = () => {
    if (!paramsAreSet()) {
      return null
    }
    return (
      <div className="upload-ui">
        <input
          className={`border rounded-md text-sm block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 `}
          accept=".png,.jpg,.jpeg"
          type="file"
          onChange={onChangeUploadField}
          multiple={true}
        />
      </div>
    )
  }

  //
  //  :v3:

  const renderUploadDestinationSummary = () => {
    if (!type || !room) {
      return
    }
    return (
      <div className="flex items-center py-8 w-full bg-gray-900">
        <span className="text-center w-full ">
          Uploading <span className="text-3xl px-2 font-bold	">{type}</span> for the{' '}
          <span className="text-3xl px-2 font-bold	">{room}</span> room
        </span>
      </div>
    )
  }

  const paramsAreSet = () => {
    return type && room
  }

  const renderErrorsUI = () => {
    if (!paramsAreSet()) {
      return null
    }
    if (Object.keys(invalidFiles).length === 0) {
      return null
    }

    const htmls = []
    // eslint-disable-next-line
    for (const [k, v] of Object.entries(invalidFiles)) {
      htmls.push(<div key={k}>{k}</div>)
    }
    return (
      <div className="w-full p-8 bg-red-800 mt-4 relative text-sm">
        <button className="top-0 right-0 p-4 absolute" onClick={onClickClearErrors}>
          x
        </button>
        <h2 className="text-xl">Files with Errors</h2>
        <p className="pb-2">
          The following file names do not contain an ARID in the name and cannot be processed by this tool.
        </p>
        <hr className="py-2" />
        <div>{htmls}</div>
      </div>
    )
  }

  const renderFilesToUploadUI = () => {
    if (!paramsAreSet()) {
      return null
    }
    if (Object.keys(filesToUpload).length === 0) {
      return null
    }

    const _filesToUpload = Object.keys(filesToUpload)
      .sort()
      .reduce((obj, key) => {
        obj[key] = filesToUpload[key]
        return obj
      }, {})

    const htmls = []
    for (const [k, v] of Object.entries(_filesToUpload)) {
      let classes = []
      htmls.push(
        <div className={classes.join(' ')} key={k}>
          {k} {Math.round(v.size / 1024)} kB
        </div>
      )
    }
    return (
      <div className="w-full p-8 bg-green-800 mt-4 relative text-sm">
        <button className="top-0 right-0 p-4 absolute" onClick={onClickClear}>
          x
        </button>
        <h2 className="text-xl">Files to Upload</h2>
        <p className="">The following files will be uploaded and published onto the network.</p>
        <hr className="py-2" />
        <div>{htmls}</div>

        <button
          className="mt-2 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
          onClick={uploadAllValidFiles}
        >
          Upload All ({Object.keys(filesToUpload).length} files)
        </button>
      </div>
    )
  }

  return (
    <>
      <RedirectIfNotLoggedIn />

      <Content>
        <div className="container mx-auto px-4 mb-16">
          <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
            <img alt="" className="image m-4 h-96 mx-auto" src={roomImages[room]} />
            <hr className="h-px bg-blue-900 border-0" />
            <div>
              {renderUploadDestinationSummary()}
              {renderUploadUI()}
              {renderFilesToUploadUI()}
              {renderErrorsUI()}
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}
