/*

  x
  X

  :description

*/

//
//  :react & redux:
import React, { useState } from 'react'

//
//  :components:
import { Content } from '../../components/Content'
import { RedirectIfNotLoggedIn } from '../../components/RedirectIfNotLoggedIn'
import { useDispatch } from 'react-redux'
import { uploadProductPricesAsync } from '../../features/upload-to-s3/uploadToS3Slice'
import { DistributorsDropdown } from '../../components/DistributorsDropdown'
import { postTriggerUpdateProductPricesAsync } from '../../features/api/apiSlice'

//
//  :component:
export const ProductPrices = props => {
  const [fileToUpload, setFileToUpload] = useState('')
  const [distributor, setDistributor] = useState('')
  const [language, setLanguage] = useState('')

  //
  //  :state:
  const dispatch = useDispatch()

  const onClickClear = () => {
    fileToUpload('')
    //
    //  TODO: Get a handle on the <input> element.
    window.location.reload()
  }

  const onChangeUploadField = event => {
    //
    //  :step 1:
    //  Get a handle on our input field.
    const field = event.target

    //
    //  :step 2:
    //  If there were files, we need to save them into our files array.
    const file = Array.from(field.files)[0]
    if (!file) {
      return
    }
    //
    //  If there are changes, update our local state.
    setFileToUpload(file)

    //
    //  OK, now clear the UI of the file upload element.
    field.value = ''

    //
    //  All done, we have processed the files given to us, but not actually uploaded anything.
    return true
  }

  const uploadFileToUpload = async event => {
    //
    //  :step 1:
    //  Upload the file to the API.
    await dispatch(
      uploadProductPricesAsync({
        distributor: distributor,
        language: language,
        file: fileToUpload,
      })
    )
    //
    //  :step 2:
    //  Now that there is a now file there, reprocess the prices for our distributor/language.
    await dispatch(
      postTriggerUpdateProductPricesAsync({
        distributor: distributor,
        language: language,
      })
    )
    //
    //  Ok, now clear our UI.
    setFileToUpload('')
  }

  const onChangeDistributorLanguage = event => {
    setDistributor(event.target.value.split('|')[0])
    setLanguage(event.target.value.split('|')[1])
  }

  const renderUploadUI = () => {
    return (
      <div className="upload-ui mt-2">
        <input
          className={`border rounded-md text-sm block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 `}
          accept=".xlsx"
          type="file"
          onChange={onChangeUploadField}
          multiple={true}
        />
      </div>
    )
  }

  const renderFileToUploadUI = () => {
    if (!fileToUpload) {
      return null
    }
    return (
      <div className="w-full p-8 bg-green-800 mt-4 relative text-sm">
        <button className="top-0 right-0 p-4 absolute" onClick={onClickClear}>
          x
        </button>
        <h2 className="text-xl">File to Upload</h2>
        <p className="">The following file will be uploaded and published onto the network.</p>
        <hr className="py-2" />
        <div>
          {fileToUpload.name} {Math.round(fileToUpload.size / 1024)} kB
        </div>

        <button
          className="mt-2 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
          onClick={uploadFileToUpload}
        >
          Upload file
        </button>
      </div>
    )
  }

  return (
    <>
      <RedirectIfNotLoggedIn />

      <Content>
        <div className="container mx-auto px-4 mb-16">
          <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
            <h2 className="text-3xl">Product Prices</h2>
            <p className="mb-2 italic text-sm">Select a distributor below to upload the product prices.</p>
            <hr className="h-px bg-blue-900 border-0" />
            <div className="mt-2">
              <DistributorsDropdown click={onChangeDistributorLanguage} fill={true}></DistributorsDropdown>
              {distributor && language && renderUploadUI()}
              {renderFileToUploadUI()}
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}
