/*

  h o m e
  Home

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :components:
import { Content } from '../../components/Content'
import { RedirectIfNotLoggedIn } from '../../components/RedirectIfNotLoggedIn'
import { listAssetsAsync, selectListAssetsResponseResponse } from '../../features/api/apiSlice'

//
//  :component:
export const List = props => {
  const dispatch = useDispatch()

  const [arid, setARID] = useState('')
  const [room, setRoom] = useState(false)

  const applyFilters = asset => {
    let matched = true
    if (arid) {
      if (!asset.arid.includes(arid)) {
        matched = false
      }
    }
    if (room) {
      if (asset.room !== room) {
        matched = false
      }
    }
    return matched
  }

  const renderRoomToggleSwitch = (text, roomId, handler) => {
    const enabled = roomId === room
    return (
      <button
        className={`rounded-md p-1 px-2 ${enabled ? 'bg-green-500' : 'bg-gray-500'} mr-4`}
        onClick={e => setRoom(roomId)}
      >
        {text}
      </button>
    )
  }

  const _renderMissingTexturesFilter = () => {
    return (
      <div className="mt-4">
        <h4 className="font-bold mb-1">Room</h4>
        <div className="flex gap-2">
          <button className={`rounded-md p-1 px-2 bg-gray-700 mr-4`} onClick={e => setRoom('')}>
            Clear
          </button>
          {renderRoomToggleSwitch('Professional', 'professional')}
          {renderRoomToggleSwitch('Gaming', 'gaming')}
          {renderRoomToggleSwitch('Student', 'student')}
          {renderRoomToggleSwitch('Remote', 'remote')}
          {renderRoomToggleSwitch('Editor', 'editor')}
          {renderRoomToggleSwitch('[Textures]', '*')}
        </div>
        <br />
      </div>
    )
  }

  const renderList = () => {
    if (!assets) {
      return
    }

    const htmls = []
    let isDarkened = false
    assets.map(row => {
      if (!applyFilters(row)) {
        return false
      }
      htmls.push(
        <tr
          className={`bg-white border-b ${isDarkened ? 'dark:bg-gray-900' : 'dark:bg-gray-800'} dark:border-gray-700`}
        >
          <td className="px-6 py-4">{row.type}</td>
          <td className="px-6 py-4">{row.arid}</td>
          <td className="px-6 py-4">{row.room}</td>
          <td className="px-6 py-4">{Math.round(row.size / 1024)} kB</td>
          <td className="px-6 py-4">{row.modified.split('T')[0]}</td>
          <td className="px-6 py-4">{row.modified.split('T')[1].split('.000')[0]}</td>
        </tr>
      )
      isDarkened = !isDarkened
      return true
    })
    return (
      <div class="relative overflow-x-auto">
        {htmls.length > 1 ? <h2 className="text-center text-2xl">{htmls.length} assets</h2> : ''}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400" style={{ maxHeight: '700px' }}>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
            <th scope="col" class="px-6 py-3">
              Type
            </th>
            <th scope="col" class="px-6 py-3">
              ARID
            </th>
            <th scope="col" class="px-6 py-3">
              Room
            </th>
            <th scope="col" class="px-6 py-3">
              Size
            </th>{' '}
            <th scope="col" class="px-6 py-3">
              Date
            </th>
            <th scope="col" class="px-6 py-3">
              Time
            </th>
          </thead>
          <tbody>{htmls}</tbody>
        </table>
      </div>
    )
  }

  const assets = useSelector(selectListAssetsResponseResponse)

  useEffect(() => {
    if (!assets) {
      dispatch(listAssetsAsync())
    }
  }, [dispatch, assets])

  return (
    <>
      <RedirectIfNotLoggedIn />

      <Content>
        <div className="container mx-auto px-4 mb-16">
          <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
            <h2 className="text-3xl">Visual Assets List</h2>
            <p className="italic text-sm">This page shows a list of all known visual assets</p>
            <p className="mb-2 italic text-sm">The table below can be copied into Google Sheets via Copy/Paste</p>
            <hr className="h-px bg-blue-900 border-0" />
            <div className="mt-2">
              <h4 className="font-bold mb-1">ARID</h4>
              <div className="flex gap-2">
                <input
                  value={arid}
                  onChange={e => setARID(e.target.value)}
                  className="rounded-md text-black px-2 py-1"
                />
                <button className={`rounded-md p-1 px-2 bg-gray-700 mr-4`} onClick={e => setARID('')}>
                  Clear
                </button>
              </div>

              {_renderMissingTexturesFilter()}
              {renderList()}
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}
