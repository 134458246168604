/*

  l a y o u t
  Layout

  :description:
  The layout component functions as our top-level container.

  The <Outlet> is a component that is filled in by react router.

*/

//
//  :react & redux:
import React from "react";
import { Outlet } from "react-router-dom";
import { Navigation } from "./Navigation";

//
//  :components:
import { Page } from "./Page";

//
//  :component:
export const Layout = (props) => {

  const COLOURS = {
    background: "bg-gray-700",
    text: "text-white"
  }

  const shouldShowNavigation = () => {
    if (window.location.search.length === 1) {
      return false
    }
    if (window.location.search === "/login") {
      return false
    }
    return true
  }

  return (
    <div className={`eky-layout w-full min-h-screen flex-col ${COLOURS.background} ${COLOURS.text}`}>
      <Page>
        {shouldShowNavigation() && (<Navigation />)}
        <Outlet />
      </Page>
    </div>
  )
}