/*

  h o m e
  Home

  :description:
  The Home component.

*/

//
//  :react & redux:
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

//
//  :components:
import { Content } from '../components/Content'
import { RedirectIfNotLoggedIn } from '../components/RedirectIfNotLoggedIn'
import { selectLoginRole } from '../features/auth/authSlice'

//
//  :component:
export const Home = props => {
  //
  //  :state:
  const role = useSelector(selectLoginRole)

  const renderPageLink = (path, text) => {
    return (
      <Link className="ml-4 text-blue-500 text-xl " to={path}>
        • {text}
      </Link>
    )
  }

  const isEyekandy = () => role === 'eyekandy'
  const isVendor2D = () => role === 'vendor-2d'
  const isVendor3D = () => role === 'vendor-3d'
  const isVendorData = () => role === 'vendor-data'

  const canViewQASection = () => {
    return isEyekandy()
  }
  const canViewTexturesSection = () => {
    return isEyekandy() || isVendor3D()
  }
  const cavnViewThumbnailsSection = () => {
    return isEyekandy() || isVendor2D()
  }
  const canViewProductDataSection = () => {
    return isEyekandy() || isVendorData()
  }
  const canViewProductPricesSection = () => {
    return isEyekandy() || isVendorData()
  }
  /*
  const canViewConfigSection = () => {
    return isEyekandy()
  }
  */
  const canViewProductMappingSection = () => {
    return isEyekandy()
  }

  const renderSections = () => {
    return (
      <div>
        {renderConfigSection()}
        {renderQASection()}
        {renderTexturesSection()}
        {renderThumbnailsSection()}
        {renderProductDataSection()}
        {renderProductPricesSection()}
        {renderProductMappingSection()}
      </div>
    )
  }

  const renderConfigSection = () => {
    return null
    /*
    if (!canViewConfigSection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">Config</h2>
        <p className="mb-2 italic text-sm">Pages to control the configuration for our retailers</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/config/product-mapping', 'Product Mapping')}</div>
      </div>
    )
    */
  }

  const renderQASection = () => {
    if (!canViewQASection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">QA</h2>
        <p className="mb-2 italic text-sm">Pages to assist with QA</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/qa/viewer', 'QA Viewer')}</div>
        <div className="flex flex-col mt-2">{renderPageLink('/qa/report', 'Visual Assets Report by Distributor')}</div>
        <div className="flex flex-col mt-2">{renderPageLink('/qa/list', 'List all Visual Assets')}</div>
      </div>
    )
  }

  const renderTexturesSection = () => {
    if (!canViewTexturesSection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">Visual Assets: Textures</h2>
        <p className="mb-2 italic text-sm">Pages to assist with textures</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/upload/textures/professional', 'Professional')}</div>
        <div className="flex flex-col mt-2">{renderPageLink('/upload/textures/gaming', 'Gaming')}</div>
        <div className="flex flex-col mt-2">{renderPageLink('/upload/textures/student', 'Student')}</div>
        <div className="flex flex-col mt-2">{renderPageLink('/upload/textures/remote', 'Remote')}</div>
        <div className="flex flex-col mt-2">{renderPageLink('/upload/textures/editor', 'Editor')}</div>
      </div>
    )
  }

  const renderThumbnailsSection = () => {
    if (!cavnViewThumbnailsSection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">Visual Assets: Thumbnails</h2>
        <p className="mb-2 italic text-sm">Pages to assist with thumbnails</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/upload/thumbnails', 'Upload Thumbnails')}</div>
      </div>
    )
  }

  const renderProductDataSection = () => {
    if (!canViewProductDataSection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">Products: Data</h2>
        <p className="mb-2 italic text-sm">Pages to assist with product data</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/upload/products', 'Upload Product Data')}</div>
        <div className="flex flex-col mt-2">
          <a href={process.env.PUBLIC_URL + '/templates/eyekandy-pc-rooms-product-data-300.xlsx'}>
            [Download Template]
          </a>
        </div>
      </div>
    )
  }

  const renderProductPricesSection = () => {
    if (!canViewProductPricesSection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">Products: Prices</h2>
        <p className="mb-2 italic text-sm">Pages to assist with product prices</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/upload/prices', 'Upload Product Prices')}</div>
        <div className="flex flex-col mt-2">
          <a className="" href={process.env.PUBLIC_URL + '/templates/eyekandy-pc-rooms-product-prices-300.xlsx'}>
            [Download Template]
          </a>
        </div>
      </div>
    )
  }

  const renderProductMappingSection = () => {
    if (!canViewProductMappingSection()) {
      return
    }
    return (
      <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
        <h2 className="text-3xl">Products: Mapping</h2>
        <p className="mb-2 italic text-sm">Pages to assist with uploading product mappings</p>
        <hr className="h-px bg-blue-900 border-0" />
        <div className="flex flex-col mt-2">{renderPageLink('/upload/mappings', 'Upload Product Mapping')}</div>
        <div className="flex flex-col mt-2">
          <a href={process.env.PUBLIC_URL + '/templates/eyekandy-pc-rooms-product-mapping-310.xlsx'}>
            [Download Template]
          </a>
        </div>
      </div>
    )
  }

  return (
    <>
      <RedirectIfNotLoggedIn />

      <Content>
        <div className="container mx-auto px-4 mb-16">{renderSections()}</div>
      </Content>
    </>
  )
}
