import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from './authAPI';


export const LOCAL_STORAGE_TOKEN_KEY = "jwt"

export const decodeToken = (token) => {
  return JSON.parse(atob(token.split(".")[1]))
}

export const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
}


//
//  TODO: Handle reading the token from local storage on login.
const initialState = {
  username: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? decodeToken(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)).sub : null,
  role: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? decodeToken(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)).role : null,
  isLoggedIn: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? true : false,
  login: {
    status: "idle",
    requesting: false,
    error: null,
    response: null,
  }
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (credentials) => {
    const response = await login(credentials);
    return response.token || null;
  }
);



export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.login.status = "idle"
      state.login.error = null
      state.login.response = null
      state.isLoggedIn = false
      state.username = null
      state.role = null
      //
      //  Delete the token in storage.
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.login.status = 'requesting';
        state.login.requesting = true
        state.login.error = null
        state.login.response = null
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.login.status = 'idle';
        state.login.requesting = false
        state.login.response = action.payload
        //
        //  TODO: Handle setting is isLoggedIn/username/role.
        if (action.payload) {
          const token = decodeToken(action.payload)
          state.username = token.sub
          state.role = token.role
          state.isLoggedIn = true
          localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, action.payload)
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.login.status = 'error';
        state.login.requesting = false
        state.login.error = action.error
      })
  },
});

export const { logout } = authSlice.actions;

//
//  :core:
export const selectLoginUsername = (state) => state.auth.username;
export const selectLoginRole = (state) => state.auth.role;
export const selectLoginIsLoggedIn = (state) => state.auth.isLoggedIn;

//
//  :login:
export const selectLoginStatus = (state) => state.auth.login.status;
export const selectLoginRequesting = (state) => state.auth.login.requesting;
export const selectLoginError = (state) => state.auth.login.error;
export const selectLoginResponse = (state) => state.auth.login.response;



//
//  :exports:
export default authSlice.reducer;
