/*

  a p i  -  A P I
  api - API

  :description:
  A file to hold our functions that reach out to the API for a response.

  Note, these should all be marshalled through redux actions.

*/

//
//  :builtins:

//
//  :code:
import { getApiUrl } from '../../endpoints'
import { getToken } from '../auth/authSlice'

//
//  :packages:
import axios from 'axios'

//
//  :eyekandy-products:
//  Requests for the /eyekandy-products endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
export const getEyekandyProducts = async () => {
  const uri = `${getApiUrl()}/eyekandy-products`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getEyekandyProduct = async arid => {
  const uri = `${getApiUrl()}/eyekandy-products/${arid}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const saveVisualAssetToEyekandyProduct = async (type, arid, presignedUrl, roomOrId, location) => {
  //
  //  Construct the body for the call.
  const bucket = presignedUrl.match(/https:\/\/(\S+)\.s3/, 'ig')[1]
  const key = presignedUrl.match(/amazonaws\.com(\S+)\?/, 'ig')[1]
  const body = {
    type: type,
    arid: arid,
    s3: `s3://${bucket}${key}`,
    room: roomOrId,
    id: roomOrId,
    location: location,
  }
  const uri = `${getApiUrl()}/eyekandy-products/${arid}/visual-assets`
  const response = await axios.patch(uri, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

//
//  :visual-assets:
//  Requests for the /visual-assets endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
export const getPresignedURLForEyekandyVisualAssetUpload = async (type, arid, extension, mime, roomOrId, location) => {
  let uri = `${getApiUrl()}/visual-assets/upload?type=${type}&arid=${arid}&extension=${extension}&mime=${mime}`
  if (roomOrId) {
    uri += `&room=${roomOrId}`
  }
  if (location) {
    uri += `&location=${location}`
  }
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getPresignedURLForEyekandyVisualAssetUploadv2 = async (type, room, arid, extension) => {
  let uri = `${getApiUrl()}/v4/assets/upload?type=${type}&room=${room}&arid=${arid}&extension=${extension}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getPresignedURLForEyekandyProductDataUploadv2 = async (distributor, language, extension) => {
  let uri = `${getApiUrl()}/v4/products/upload?distributor=${distributor}&language=${language}&extension=${extension}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getPresignedURLForEyekandyProductPricesUploadv2 = async (distributor, language, extension) => {
  let uri = `${getApiUrl()}/v4/prices/upload?distributor=${distributor}&language=${language}&extension=${extension}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getPresignedURLForEyekandyFileUploadv2 = async (type, arid, extension) => {
  let uri = `${getApiUrl()}/files/upload?type=${type}&extension=${extension}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getPresignedURLForEyekandyProductMappingUploadv2 = async (distributor, language, extension) => {
  let uri = `${getApiUrl()}/v4/mappings/upload?distributor=${distributor}&language=${language}&extension=${extension}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getAssets = async () => {
  const uri = `${getApiUrl()}/v4/assets/list`
  const response = await axios.get(uri, null, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  try {
    response.data.sort((a, b) => {
      return a.arid.localeCompare(b.arid)
    })
  } catch (err) {}
  return response.data
}

export const getReport = async () => {
  const uri = `${getApiUrl()}/v4/assets/report`
  const response = await axios.get(uri, null, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getConfig = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/config/${distributor}?language=${language}`
  const response = await axios.get(uri, null, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getDistributors = async () => {
  const uri = `${getApiUrl()}/v4/data/distributors`
  const response = await axios.get(uri, null, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getDistributorProducts = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/products/${distributor}?language=${language}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const postTriggerUpdateVisualAssets = async (arids, roomARIDs) => {
  const uri = `${getApiUrl()}/v4/pipeline/visual-assets`
  const response = await axios.post(uri, JSON.stringify({ arids: arids, roomARIDs: roomARIDs }), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const postTriggerUpdateProductPrices = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/pipeline/prices`
  const response = await axios.post(uri, JSON.stringify({ distributor: distributor, language: language }), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const postTriggerUpdateProductData = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/pipeline/products`
  const response = await axios.post(uri, JSON.stringify({ distributor: distributor, language: language }), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const postTriggerUpdateProductMapping = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/pipeline/mapping`
  const response = await axios.post(uri, JSON.stringify({ distributor: distributor, language: language }), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const postTriggerUpdateAppConfig = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/pipeline/app-config`
  const response = await axios.post(uri, JSON.stringify({ distributor: distributor, language: language }), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getRoomPopulationForDistributor = async (distributor, language) => {
  const uri = `${getApiUrl()}/v4/data/population?distributor=${distributor}&language=${language}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

//
//  :distributor-products:
//  Requests for the /distributor-products endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -

//
//  :configured-products:
//  Requests for the /configured-products endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
export const getConfiguredProducts = async () => {
  const uri = `${getApiUrl()}/configured-products`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const createConfiguredProduct = async configuredProductObject => {
  const uri = `${getApiUrl()}/configured-products`
  const response = await axios.post(uri, JSON.stringify(configuredProductObject), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const deleteConfiguredProduct = async configuredProductUuid => {
  const uri = `${getApiUrl()}/configured-products/${configuredProductUuid}`
  const response = await axios.delete(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

//
//  :maps:
//  Requests for the /maps endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
export const getRoomsMapping = async () => {
  const uri = `${getApiUrl()}/maps/rooms`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getDistributorsMapping = async () => {
  const uri = `${getApiUrl()}/maps/distributors`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getLocationsMapping = async () => {
  const uri = `${getApiUrl()}/maps/locations`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

//
//  :enums:
//  Requests for the /enums endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
