/*

  h o m e
  Home

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :components:
import { Content } from '../../components/Content'
import { RedirectIfNotLoggedIn } from '../../components/RedirectIfNotLoggedIn'
import { DistributorsDropdown } from '../../components/DistributorsDropdown'
import {
  getRoomPopulationForDistributorAsync,
  selectGetRoomPopulationForDistributorResponse,
  selectGetRoomPopulationForDistributorResponseRequesting,
} from '../../features/api/apiSlice'

export const Viewer = props => {
  const [distributor, setDistributor] = useState('')
  const [language, setLanguage] = useState('')

  //
  //  :redux:
  const dispatch = useDispatch()

  const roomsPopulationForDistributor = useSelector(selectGetRoomPopulationForDistributorResponse)
  const roomsPopulationForDistributorIsRequesting = useSelector(selectGetRoomPopulationForDistributorResponseRequesting)

  const onChangeDistributorLanguage = event => {
    const d = event.target.value.split('|')[0]
    const l = event.target.value.split('|')[1]
    setDistributor(d)
    setLanguage(l)
  }

  useEffect(() => {
    addScript()
  }, [])

  useEffect(() => {
    if (!distributor || !language) {
      return
    }
    dispatch(getRoomPopulationForDistributorAsync({ distributor: distributor, language: language }))
  }, [dispatch, distributor, language])

  const addScript = () => {
    if (!document.querySelector('#eky-point-and-place')) {
      const n = document.createElement('script')
      n.src = 'https://media.pointandplace.com/js/pointandplace.js'
      n.async = false
      n.id = 'eky-point-and-place'
      document.body.appendChild(n)
    }
  }

  const loadQAViewerForDistributorLanguage = async () => {
    console.log(distributor, language)
  }

  const getDisplayLanguage = () => {
    if (language === 'br') {
      return 'pt-BR'
    }
    return `${language}-${language.toUpperCase()}`
  }

  const renderPCRooms = () => {
    if (!distributor || !language) {
      return
    }

    if (roomsPopulationForDistributorIsRequesting) {
      return
    }
    const actualRooms = Array.from(roomsPopulationForDistributor || [])
    const availableRooms = `${JSON.stringify(actualRooms).replaceAll(/"/g, "'")}`
    const n = document.createElement('eyekandy-pc-rooms-embed')
    const props_ = {
      key: Date.now(),
      distributor: distributor,
      language: language,
      displayLanguage: getDisplayLanguage(),
      logging: 'false',
      eventLogging: 'false',
      availableRooms: availableRooms,
      unison: actualRooms.includes('unison'),
      environment: window.location.href.includes('pc-rooms-ui.') ? 'prod' : 'dev',
    }
    window.EKY_PC_ROOMS_ROOMS = actualRooms
    for (const [k, v] of Object.entries(props_)) {
      const val = `${v}`
      n.setAttribute(k, val)
      //n[k] = val
    }
    const existing = document.querySelector('eyekandy-pc-rooms-embed')
    if (existing) {
      existing.remove()
    }
    const target = document.querySelector('#pc-rooms-target')
    target.appendChild(n)
  }

  return (
    <>
      <RedirectIfNotLoggedIn />

      <Content>
        <div className="container mx-auto px-4 mb-16">
          <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
            <h2 className="text-3xl">QA Viewer</h2>
            <p className="mb-2 italic text-sm">Select the distributor below to QA their Config</p>
            <hr className="h-px bg-blue-900 border-0" />
            <div className="flex flex-col mt-2">
              <DistributorsDropdown click={onChangeDistributorLanguage} fill={true}></DistributorsDropdown>

              <button className="mt-2 bg-blue-600 text-white px-4 py-2" onClick={loadQAViewerForDistributorLanguage}>
                LOAD
              </button>
            </div>
            <div className="h-8"></div>
            <div id="pc-rooms-target" className="flex flex-col w-full bg-blue-100">
              {renderPCRooms()}
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}
