/*

  r e d i r e c t  i f  l o g g e d  i n 
  Redirect if logged in

  :description:

*/

//
//  :react & redux:
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

//
//  :actions:
import { selectLoginIsLoggedIn } from "../features/auth/authSlice";

//
//  :component:
export const RedirectIfLoggedIn = (props) => {
  const isLoggedIn = useSelector(selectLoginIsLoggedIn)
  return (
    isLoggedIn === true ? <Navigate to="/"></Navigate> : <></>
  )
}